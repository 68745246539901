import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import Directories from "../components/Home/Directories";
import FormHome from "../components/Home/FormHome";
import Map from "../components/Contact/MapContent";
import Modal from "../components/Home/Modal";
import BaseLayout from "../components/global/BaseLayout";
import CounterUp from "../components/global/CounterUp";
import ServicesHome from "../components/Services/ServicesHome_3";
import HeroSection from "../components/Home/HeroSection";
import Paletacolor from "../components/global/Paletacolor";
import BlockTwo from "../components/block/BlockTwo";
import BlockThree from "../components/block/BlockThree";
import BlockOne from "../components/block/BlockOne";

// para agregar la frase de los años de experiencia o la licencia agregar sloganPrincipal={true}
// para agregar la lista de about agregar listsAbout={true}
// para agregar la lista de servicios agregar listsServices={true}

function Home() {
  const { rpdata } = useContext(GlobalDataContext);

  return (
    <BaseLayout PageName="Home">
      <div className="md:max-w-full w-full">
        <Modal />
        <HeroSection />
        <BlockOne
          title={'contamos con 20 años de experiencia'}
          text={rpdata?.dbHome?.[0]?.text}
          image={rpdata?.gallery?.[0]}
          sloganPrincipal={false}
          listsServices={true}
        />
        {/* <Directories /> */}
        <BlockTwo
          title={rpdata?.dbSlogan?.[3]?.slogan}
          text={rpdata?.dbHome?.[1]?.text}
          image={rpdata?.gallery?.[5]}
        />
        <CounterUp image={rpdata?.stock?.[2]} />
        <BlockThree
          title={'un poco sobre nosotros'}
          text={rpdata?.dbAbout?.[0]?.text}
          image={rpdata?.gallery?.[2]}
          listsAbout={true}
        />
        <ServicesHome />
        {/* Paleta de Colores */}
        {
          rpdata?.brandingExtra?.[0]?.activo ?
            <Paletacolor />
            : null
        }
        <div
          className="bgFormHome"
          style={{ backgroundImage: `url("${rpdata?.gallery?.[3]}")` }}
        >
          <div className="relative">
            <FormHome />
          </div>
        </div>
        <Map />
      </div>
    </BaseLayout>
  );
}

export default Home;
